<template>
  <div class="about">
    <iframe width="100%" height="100%"
      src="https://maphub.net/embed_h/0i11rHPU6gmFh8W2?autoplay=1&directions=1&geolocation=1" frameborder="0"
      allow="geolocation"></iframe>
  </div>
</template>
<style scoped>
iframe {
  position:fixed;
  z-index: 5;
  margin-bottom: 15px;
  height: calc(100% - 60px);
}
</style>
<script>
  export default {
  created() {
   this.$parent.dark = true;
  }
  }
</script>